import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar({ navItems, activeTab, onTabChange }) {
  return (
    <nav className="navbar">
      <ul>
        {navItems.map((item) => (
          <li key={item.value} className={activeTab === item.value ? 'active' : ''}>
            <Link
              to={item.route} 
              onClick={() => onTabChange(item.value)} 
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Navbar;