import React, { useState, useEffect, useCallback } from 'react';
import JobDetailChart from './JobDonutChart.js';
import AccountabilityChart from './JobBarChart.js';
import Draggable from 'react-draggable';
import MarkdownIt from "markdown-it"; // Import markdown-it
import { ResizableBox } from 'react-resizable';
import { jobTabContent } from '../../../../Assets/HRStaticContent.js';
import { useSelection } from "../../../../Hooks/TabsStateContext.js";
import DropdownMenu from '../../../../Components/DropdownMenu.js'; 
import ScrollableBox from '../../../../Components/ScrollableBox.js'; 
import JDDiffChecker from '../../../../Components/JDDiffChecker.js'; 
import JDSectionSeparator from '../../../../Components/JDSectionSeparator.js'; // Import the formatter
import { API_ENDPOINTS } from '../../../../config/ApiConfig';
// import 'monaco-editor/min/vs/editor/editor.main.css';
import './JobDetails.css';

function JobDetail({ jobId, jobName, email, jdFileUri, analylizedJDUid }) {
    
  const { selectedJob, setSelectedJob, selectedItem, setSelectedItem } = useSelection();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [sortedAccountabilities, setSortedAccountabilities] = useState([]);

  const [isJDModalOpen, setJDModalOpen] = useState(false);
  const [isCompareModalOpen, setCompareModalOpen] = useState(false);
  const [jdData, setJDData] = useState(null);
  const [compareData, setCompareData] = useState(null);
  const [originalJD, setOriginalJD] = useState('');
  const [revisedJD, setRevisedJD] = useState('');
  const [generatedDefaultFormat, setGeneratedDefaultFormat] = useState("");
  const [originalDefaultFormat, setOriginalDefaultFormat] = useState("");
  const [automationJsonFormat, setAutomationJsonFormat] = useState("");
  const [jsonFormat, setJsonFormat] = useState("");
  const [markdownFormat, setMarkdownFormat] = useState(""); // State for Markdown format
  const mdParser = new MarkdownIt(); // Create an instance of MarkdownIt

  useEffect(() => {
  console.log("JobDetail received jobId:", jobId);

  const fetchJDData = async () => {
      try {
        const originalResponse = await fetch(`${API_ENDPOINTS.ORIGINAL_JOB_DESCRIPTION}?job_id=${jobId}&email=${email}`);
        if (!originalResponse.ok) throw new Error('Failed to fetch original JD');
        const originalData = await originalResponse.json();
        setOriginalJD(originalData.job_description);

        const revisedResponse = await fetch(`${API_ENDPOINTS.SB_JOB_DESCRIPTION}?job_id=${jobId}&email=${email}`);
        if (!revisedResponse.ok) throw new Error('Failed to fetch revised JD');
        const revisedData = await revisedResponse.json();

        const { 
          originalDefaultFormat, 
          generatedDefaultFormat, 
          JDJsonFormat, 
          automationJsonFormat, 
          error 
        } = JDSectionSeparator(revisedData.sb_job_description);
        if (error) throw new Error(error);

        setRevisedJD(generatedDefaultFormat);
        setOriginalJD(originalDefaultFormat);
        setAutomationJsonFormat(automationJsonFormat);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchJDData();
  }, [jobId]);

  // Function to call the update API
  const updateErrorInBigQuery = async () => {
    console.log('Error found, update big query');
    try {
      const response = await fetch(API_ENDPOINTS.UPDATE_BIGQUERY, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          table_name: 'job_desc',  // Replace with the actual table name
          row_data: { 'error_message': error },  // Error message as row data
          where_clause: `sb_unique_id = '${jobId}'`  // Where clause using job ID
        })
      });

      if (!response.ok) {
        throw new Error('Failed to update error in BigQuery');
      }
      console.log('Error successfully updated in BigQuery.');
    } catch (error) {
      console.error('Error calling update API:', error);
    }
  };

  // Trigger the update API call when error exists
  useEffect(() => {
    if (error) {
      updateErrorInBigQuery();
    }
  }, [error]);

  // Fetch Job Data
  useEffect(() => {
    const fetchJobData = async () => {
      try {
        console.log('Fetching job data...');
        setLoading(true);
        // const response = await fetch(API_ENDPOINTS.AUTOMATION_POTENTIAL);
        // if (!response.ok) throw new Error('Network response was not ok');
        
        console.log("check autmoation", automationJsonFormat)


        const cleanJsonString = automationJsonFormat.replace(/```json|\`\`\`/g, '').trim();

        // Parse the cleaned JSON string
        const data = JSON.parse(cleanJsonString);
        console.log('Job data fetched:', data);
        setJob(data);


        // Extract min and max values
        // Check if it's in a range format or a single number
        const overallPotential = data.overall_role_automation_potential;
        const [minPotential, maxPotential] = overallPotential.includes('-')
          ? overallPotential.split('-').map((val) => parseFloat(val))
          : [parseFloat(overallPotential), parseFloat(overallPotential)];
          
        const averageAutomation = ((minPotential + maxPotential) / 2).toFixed(2);
        const unaffectedPercentage = (100 - averageAutomation).toFixed(2);
        setChartData([averageAutomation, unaffectedPercentage]);
        console.log('Chart data set:', chartData);

        // Sort accountabilities
        const sorted = data.accountability_automation_potential.sort(
          (a, b) => parseFloat(b.automation_potential) - parseFloat(a.automation_potential)
        );
        setSortedAccountabilities(sorted);
        console.log('Sorted accountabilities:', sorted);
      } catch (error) {
        console.error('Error fetching job data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (automationJsonFormat) {
      fetchJobData();
    }

    // fetchJobData();
  }, [jobName, automationJsonFormat]);



  // Fetch Compare Data
  const fetchCompareData = useCallback(async () => {
    try {
      console.log('Fetching compare data...');
      const response = await fetch(API_ENDPOINTS.COMPARE_DATA);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      console.log('Compare data fetched:', data);
      setCompareData(data.comparison);
    } catch (error) {
      console.error('Error fetching compare data:', error);
      setError(error.message);
    }
  }, []);

  const handleSaveChanges = async (updatedJD) => {
  try {
    const response = await fetch(API_ENDPOINTS.UPDATE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ jobId, updatedJD }), // Adjust the body as needed
    });

    if (!response.ok) {
      throw new Error("Failed to save changes.");
    }
    
    // Optionally, handle success response
    console.log("Job description updated successfully.");
  } catch (error) {
    console.error("Error saving changes:", error);
    // Optionally, display an error message to the user
  }
};




  const dropdownItems = [
    { value: "level", label: "Level" },
    { value: "role", label: "Role" },
  ];

  if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error}</div>;
  // if (!job) return <div>No job data available</div>;

  return (
    <div className="job-detail">
      <div className="dropdown-section">
        <h1>{jobTabContent.title}</h1>
        <DropdownMenu 
          options={dropdownItems}
          placeholder="Select an option"
          value={selectedItem}
          onItemSelect={(item) => {
            setSelectedItem(item); 
            if (item === null || item.value === "") {
              setSelectedJob(null);
            } else {
              console.log('Selected Item:', item);
            }
          }}
        />
      </div>
      <div className="search-section">
            <input 
              type="text" 
              placeholder={selectedJob?.name || "Search..."} 
            />
      </div>

      {/* Display error message if it exists */}
      {/* {error && <div className="error-message">Error: {error}</div>} */}

      {/* Conditional rendering for job data */}
      {error ? (
        <div className="error-message">Error: {error}</div>
      ) : (
        <>
          <div className="diff-checker-container">
            <h1>Job Description Diff Checker</h1>
            <JDDiffChecker
              originalJD={originalJD}
              revisedJD={revisedJD}
              width="100%"
              height="500px"
              onSaveChanges={handleSaveChanges}
            />
          </div>

          <div className="content-layout">
            <div className="left-box content-box-wrapper">
              <ScrollableBox
                height="500px"
                width="100%"
                content={
                  <>
                    <div className="button-group">
                      <button onClick={() => setJDModalOpen(true)}>View SB.AI JD</button>
                      <button onClick={() => { 
                        setCompareModalOpen(true); 
                        fetchCompareData(); 
                      }}>Compare</button>
                    </div>
                    <h4>Job automation possible with current tech</h4>
                    <div className="chart-box-donut">
                      {chartData && <JobDetailChart data={chartData} />}
                    </div>
                  </>
                }
              />
              <p className="box-description">Key for the donut chart box</p>
            </div>

            <div className="right-box content-box-wrapper">
              <ScrollableBox
                height="500px"
                width="100%"
                content={
                  <>
                    <h4>Job automation possible with current technology, by key accountabilities (average across selection)*</h4>
                    {sortedAccountabilities.map((item, index) => (
                      <div key={index} className="chart-item">
                        <p>{item.accountability}</p>
                        <div className="chart-box">
                          <AccountabilityChart automationPotential={parseFloat(item.automation_potential)} />
                        </div>
                      </div>
                    ))}
                  </>
                }
              />
              <p className="box-description">Key for the bar chart box</p>
            </div>
          </div>

          {/* Modal Components */}
          {isJDModalOpen && (
            <Draggable>
              <ResizableBox
                width={300}
                height={300}
                minConstraints={[150, 150]}
                maxConstraints={[800, 600]}
                className="modal modal-left"
              >
                <div className="modal-content">
                  <h2>SB.AI JD Data</h2>
                  {originalJD ? <div>{JSON.stringify(originalJD)}</div> : <div>Loading JD data...</div>}
                  <button onClick={() => setJDModalOpen(false)}>Close</button>
                </div>
              </ResizableBox>
            </Draggable>
          )}

          {isCompareModalOpen && (
            <Draggable>
              <ResizableBox
                width={300}
                height={300}
                minConstraints={[150, 150]}
                maxConstraints={[800, 600]}
                className="modal modal-right"
              >
                <div className="modal-content">
                  <h2>Compare Data</h2>
                  {compareData ? <div>{JSON.stringify(compareData)}</div> : <div>Loading compare data...</div>}
                  <button onClick={() => setCompareModalOpen(false)}>Close</button>
                </div>
              </ResizableBox>
            </Draggable>
          )}
        </>
      )}
    </div>
  );
}

export default JobDetail;