import * as monaco from "monaco-editor";
import React, { useEffect, useRef, useMemo, useState } from "react";
import "./JDDiffChecker.css"; // Import the CSS file

function JDDiffChecker({
  originalJD,
  revisedJD,
  width = "100%",
  height = "100%",
  theme = "vs", // You can switch themes, e.g., "vs-dark"
  onSaveChanges, // Callback function to handle save API call
}) {
  const containerElement = useRef(null);
  const editor = useRef(null);
  const modifiedModel = useRef(null);
  const [hasChanges, setHasChanges] = useState(false);

  const style = useMemo(
    () => ({
      width,
      height,
    }),
    [width, height]
  );

  useEffect(() => {
    if (containerElement.current) {
      // Create the diff editor
      editor.current = monaco.editor.createDiffEditor(
        containerElement.current,
        {
          automaticLayout: true,
          wordWrap: "on", // Enable word wrapping
          theme, // Set the theme here
          renderIndicators: true, // Enable word-level highlighting
        }
      );

      // Create models for original and modified job descriptions with markdown language
      const originalModel = monaco.editor.createModel(
        originalJD || "",
        "markdown"
      );
      modifiedModel.current = monaco.editor.createModel(
        revisedJD || "",
        "markdown"
      );

      // Set models to the diff editor
      editor.current.setModel({
        original: originalModel,
        modified: modifiedModel.current,
      });

      // Make only the modified side editable
      modifiedModel.current.updateOptions({ readOnly: false });
      originalModel.updateOptions({ readOnly: true });

      // Track changes in the modified model
      // Track changes in the modified model
      const onChangeDisposable = modifiedModel.current.onDidChangeContent(
        () => {
          const updatedText = modifiedModel.current.getValue();
          // Compare the updated text with the original JD to set hasChanges
          setHasChanges(updatedText !== originalJD); // Only set true if content is different
        }
      );

      // Cleanup on component unmount
      return () => {
        if (editor.current) editor.current.dispose();
        if (onChangeDisposable) onChangeDisposable.dispose();
      };
    }
  }, [originalJD, revisedJD, theme]);

  // Function to handle save action
  const handleSave = () => {
    if (hasChanges && onSaveChanges) {
      const updatedText = modifiedModel.current.getValue();
      onSaveChanges(updatedText); // Call the API with updated JD
      setHasChanges(false); // Reset the change status
    }
  };

  return (
    <div style={{ width, height }}>
      <div className="jd-labels">
        <span className="jd-label">Original JD</span>
        <span className="jd-label">Skillsbridge JD (Editable)</span>
      </div>
      <div
        ref={containerElement}
        style={style}
        className="react-monaco-editor-container" // Use the class from the CSS
      />
      {hasChanges && (
        <button className="save-button" onClick={handleSave}>
          Save Changes
        </button>
      )}
    </div>
  );
}

export default JDDiffChecker;