import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../State/AuthContext";
import Login from "../../Components/LoginFunction";
import "./HomePage.css";

const HomePage = () => {
  const navigate = useNavigate();
  const { email, setEmail } = useContext(AuthContext);
  const [inputEmail, setInputEmail] = useState(""); // Local state for input

  const handleLoginSubmit = (event) => {
    event.preventDefault();
    if (inputEmail.trim()) {
      // Ensure input is not empty
      setEmail(inputEmail);
    }
  };

  const handleSelectChange = (event) => {
    const selectedPath = event.target.value;
    if (selectedPath) navigate(selectedPath);
  };

  return (
    <div className="home-page">
      {!email ? (
        <LoginContainer
          handleLoginSubmit={handleLoginSubmit}
          inputEmail={inputEmail}
          setInputEmail={setInputEmail}
        />
      ) : (
        <LoggedInView email={email} handleSelectChange={handleSelectChange} />
      )}
    </div>
  );
};

// Extracted login container for better readability and reuse
const LoginContainer = ({ handleLoginSubmit, inputEmail, setInputEmail }) => (
  <div className="login-container">
    <h1>Login to SkillsBridge.AI</h1>
    <Login /> {/* Google Login */}
  </div>
);

// Extracted logged-in view for clarity
const LoggedInView = ({ email, handleSelectChange }) => (
  <>
    <div className="dropdown-container">
      <select onChange={handleSelectChange}>
        <option value="">Select Access</option>
        <option value="/hr">HR Access</option>
        <option value="/employee">Employee Access</option>
      </select>
    </div>
    <h1>Welcome to SkillsBridge.AI</h1>
    <p>Logged in as: {email}</p>
  </>
);

export default HomePage;
