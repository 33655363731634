import React, { createContext, useState, useContext } from "react";

// Create a Context
const TabStateContext = createContext();

export const useSelection = () => {
  return useContext(TabStateContext);
};

export const TabStateProvider = ({ children }) => {
  const [selectedItem, setSelectedItem] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedJob, setSelectedJob] = useState(null);

  return (
    <TabStateContext.Provider
      value={{
        selectedItem,
        setSelectedItem,
        searchQuery,
        setSearchQuery,
        selectedJob,
        setSelectedJob,
      }}
    >
      {children}
    </TabStateContext.Provider>
  );
};
