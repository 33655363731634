function JDSectionSeparator(jobDescription) {
  console.log("Received JD?:", jobDescription);

  // Check if the jobDescription is valid
  if (!jobDescription || typeof jobDescription !== "string") {
    console.error(
      "Invalid job description format: Expected a non-empty string."
    );
    return {
      error: "Invalid job description format: Expected a non-empty string.",
      originalDefaultFormat: "",
      generatedDefaultFormat: "",
      JDJsonFormat: "",
      automationJsonFormat: "",
    };
  }

  // Use case-insensitive regex to search for section headers and allow optional ':' at the end
  const jdJsonRegex = /\bjd\s*json\s*format:?\s*/i;
  const generatedDefaultFormatRegex = /\bgenerated\s*default\s*format:?\s*/i;
  const originalDefaultFormatRegex = /\boriginal\s*default\s*format:?\s*/i;
  const automationJsonRegex = /\bautomation\s*json\s*format:?\s*/i;

  // Check if all required sections are present
  if (!jdJsonRegex.test(jobDescription)) {
    console.error("Error: Missing 'JD json format' section.");
    return {
      error: "Missing 'JD json format' section.",
      originalDefaultFormat: "",
      generatedDefaultFormat: "",
      JDJsonFormat: "",
      automationJsonFormat: "",
    };
  }
  if (!generatedDefaultFormatRegex.test(jobDescription)) {
    console.error("Error: Missing 'generated default format' section.");
    return {
      error: "Missing 'generated default format' section.",
      originalDefaultFormat: "",
      generatedDefaultFormat: "",
      JDJsonFormat: "",
      automationJsonFormat: "",
    };
  }
  if (!originalDefaultFormatRegex.test(jobDescription)) {
    console.error("Error: Missing 'original default format' section.");
    return {
      error: "Missing 'original default format' section.",
      originalDefaultFormat: "",
      generatedDefaultFormat: "",
      JDJsonFormat: "",
      automationJsonFormat: "",
    };
  }
  if (!automationJsonRegex.test(jobDescription)) {
    console.error("Error: Missing 'automation json format' section.");
    return {
      error: "Missing 'automation json format' section.",
      originalDefaultFormat: "",
      generatedDefaultFormat: "",
      JDJsonFormat: "",
      automationJsonFormat: "",
    };
  }

  // Split the job description into sections based on "JD json format"
  const sections = jobDescription.split(jdJsonRegex);

  // Split the first section into "original default format" and "generated default format"
  const defaultSections = sections[0].split(generatedDefaultFormatRegex);

  // Get the "original default format"
  const originalDefaultFormat =
    defaultSections[0]?.replace(originalDefaultFormatRegex, "").trim() || "";

  // Get the "generated default format"
  const generatedDefaultFormat =
    defaultSections[1]?.replace(generatedDefaultFormatRegex, "").trim() || "";

  // Get the "JD json format" by trimming sections[1]
  const JDJsonSection = sections[1]?.split(automationJsonRegex);
  const JDJsonFormat = JDJsonSection[0]?.trim() || "";

  // Get the "automation json format" by trimming JDJsonSection[1]
  const automationJsonFormat = JDJsonSection[1]?.trim() || "";

  console.log("Separated sections:");
  console.log("Original Default Format:", originalDefaultFormat);
  console.log("Generated Default Format:", generatedDefaultFormat);
  console.log("JD Json Format:", JDJsonFormat);
  console.log("Automation Json Format:", automationJsonFormat);
  console.log("");

  // Return all four sections
  return {
    error: null, // No error
    originalDefaultFormat,
    generatedDefaultFormat,
    JDJsonFormat,
    automationJsonFormat,
  };
}

export default JDSectionSeparator;
